import { Link } from "gatsby"
import React, { useContext } from "react"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import minus from "../../images/Healthcare/minus.svg"
import plus from "../../images/Healthcare/plus.svg"
import * as styles from "./Integration.module.scss"
import "./Integration.scss"

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
        >
          {children}
        </div>
        <div type="button">
          {isCurrentEventKey ? (
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/mi_16441c2c8b.svg"
              }
              className={styles.quesColorSelected}
              alt="icon"
              width={48}
              height={48}
            />
          ) : (
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007383_ed2cd46316.svg"
              className={styles.quesColor}
              alt="icon"
              width={48}
              height={48}
            />
          )}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const Integration = ({ strapiData }) => {
  return (
    <div className={styles.integrationCSDOuter}>
      <div className={styles.integrationCSD}>
        <Container>
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.subTitle}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div
            className={styles.subTitle2}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle2,
            }}
          />

          <Accordion>
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="integrationAccordian">
                  {strapiData?.cards &&
                    strapiData?.cards?.map(
                      (e, i) =>
                        i < 3 && (
                          <Card key={i}>
                            <ContextAwareToggle eventKey={`ek-${i}`}>
                              <div className={styles.accImg}>
                                <h3 className="m-0 p-0" type="button">
                                  {e?.title}
                                </h3>
                              </div>
                            </ContextAwareToggle>
                            <Accordion.Collapse eventKey={`ek-${i}`}>
                              <Card.Body className={styles.textColor}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: e?.subTitle,
                                  }}
                                />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        )
                    )}
                </div>
              </Col>
              <Col lg={6} className={styles.nextGenRight}>
                <div className="integrationAccordian">
                  {strapiData?.cards &&
                    strapiData?.cards?.map(
                      (e, i) =>
                        i > 2 && (
                          <Card key={i}>
                            <ContextAwareToggle eventKey={`ek-${i}`}>
                              <div className={styles.accImg}>
                                <h3 className="m-0 p-0" type="button">
                                  {e?.title}
                                </h3>
                              </div>
                            </ContextAwareToggle>
                            <Accordion.Collapse eventKey={`ek-${i}`}>
                              <Card.Body className={styles.textColor}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: e?.subTitle,
                                  }}
                                />
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        )
                    )}
                </div>
              </Col>
            </Row>
          </Accordion>
        </Container>
      </div>
      <Container className={styles.Container2}>
        <img
          src="https://invozone-backend.s3.us-east-1.amazonaws.com/custom_software_integration_9d42d356bb.webp"
          alt="Custom Software Integation"
          loading="lazy"
          width={1280}
          height={564}
          style={{ width: "auto", height: "auto" }}
        />
      </Container>
    </div>
  )
}

export default Integration
