// extracted by mini-css-extract-plugin
export var TechStacksWebHireAndroid = "TechStack-module--TechStacksWebHireAndroid--c7575";
export var cardBg = "TechStack-module--cardBg--f0166";
export var cards = "TechStack-module--cards--1241e";
export var cardsContentAngular = "TechStack-module--cardsContentAngular--68654";
export var description = "TechStack-module--description--02415";
export var heading = "TechStack-module--heading--394dd";
export var iconssCard = "TechStack-module--iconssCard--19794";
export var nav = "TechStack-module--nav--b3c7d";
export var navItem = "TechStack-module--nav-item--5343e";
export var navbarBlock = "TechStack-module--navbarBlock--c4a4b";
export var tabData = "TechStack-module--tabData--adbcb";
export var tech = "TechStack-module--tech--f67bc";
export var techIcon = "TechStack-module--techIcon--150de";
export var techImg = "TechStack-module--techImg--623c1";
export var technologyIcon = "TechStack-module--technologyIcon--c1f23";
export var title = "TechStack-module--title--fdcea";