import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  return (
    <div className={styles.banner}>
      <Container className={styles.innerCustomSD}>
        <div className={styles.customSD}>
          <Container>
            <div className={styles.customSDHeading}>
              <p
                className={styles.subTitle}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.title,
                }}
              ></p>
              <h1
                className={styles.bannerHeading}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle2,
                }}
              />
            </div>
            <Link to={strapiData?.buttons[0]?.url} className={styles.btn}>
              {strapiData?.buttons[0]?.title}
              <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                decoding="async"
                loading="lazy"
                alt="explore icon"
                width={32}
                height={32}
              />
            </Link>
            <div className={styles.csdProject}>
              <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/client_logo_149f7aed77.webp"
                alt="logos"
                loading="lazy"
              />
              {/* <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/appwork_f1095e099c.png"
                decoding="async"
                loading="lazy"
                alt="app work"
              />
              <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/column_ee4655a778.png"
                decoding="async"
                loading="lazy"
                alt="column"
              />
              <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/fresh_prep_logo_9bd992291b.png"
                decoding="async"
                loading="lazy"
                alt="freshprep"
              /> */}
            </div>
          </Container>
        </div>
      </Container>
    </div>
  )
}

export default Banner
