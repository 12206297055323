import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/custom-soft-dev/Banner"
import BannerSec from "../components/custom-soft-dev/BannerSec"
import Benefits from "../components/custom-soft-dev/Benefits"
import CaseStudies from "../components/custom-soft-dev/CaseStudies"
import Industries from "../components/custom-soft-dev/Industries"
import Integration from "../components/custom-soft-dev/Integration"
import Offering from "../components/custom-soft-dev/Offering"
import Process from "../components/custom-soft-dev/Process"
import ServicesCSD from "../components/custom-soft-dev/Services"
import TechStack from "../components/custom-soft-dev/TechStack"
import TechStackMobile from "../components/custom-soft-dev/TechStackMobile"
import Testimonials from "../components/custom-soft-dev/Testimonials"
import WhyChooseUs from "../components/custom-soft-dev/WhyChooseUs"
import MainLayout from "../layouts/MainLayout"

const CustomSoftwareDev = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const sec1 = data?.strapiPage?.sections[0]
  const sec2 = data?.strapiPage?.sections[1]
  const sec3 = data?.strapiPage?.sections[2]
  const sec4 = data?.strapiPage?.sections[3]
  const sec5 = data?.strapiPage?.sections[4]
  const sec6 = data?.strapiPage?.sections[5]
  const sec7 = data?.strapiPage?.sections[6]
  const sec8 = data?.strapiPage?.sections[7]
  const sec9 = data?.strapiPage?.sections[8]
  const sec10 = data?.strapiPage?.sections[9]
  const sec11 = data?.strapiPage?.sections[10]
  const sec12 = data?.strapiPage?.sections[11]
  const sec13 = data?.strapiPage?.sections[12]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={sec1} />
      <ServicesCSD strapiData={sec2} />
      <Integration strapiData={sec3} />
      <WhyChooseUs strapiData={sec4} />
      <Benefits strapiData={sec5} />
      <Process strapiData={sec6} />
      <BannerSec strapiData={sec7} />
      <CaseStudies strapiData={sec8} />
      <Offering strapiData={sec9} />
      <Industries strapiData={sec10} />
      {!isMobile && !isTablet ? (
        <TechStack strapiData={sec11} />
      ) : (
        <TechStackMobile strapiData={sec11} />
      )}
      <Testimonials strapiData={sec12} />
      <Faqs strapiData={sec13} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}
export const query = graphql`
  query servicePage {
    strapiPage(slug: { eq: "custom-software-development" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            url
            title
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default CustomSoftwareDev

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
    />
  )
}
