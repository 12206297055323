import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./TechStack.scss"
import * as styles from "./TechStack.module.scss"

const data = [
  {
    title: "Backend",
    tech: [
      {
        name: "NodeJS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_7442e6d3af.svg",
      },
      {
        name: "Python",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_d1c39e4e93.svg",
      },
      {
        name: "Elixir",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/elixir_04118af901.svg",
      },
      {
        name: "Ruby on Rails",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ruby_on_rails_086dade430.svg",
      },
      {
        name: "Java",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_1daa91a6fb.svg",
      },
      {
        name: "PHP",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/php_322b002a86.svg",
      },
      {
        name: "GO",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/golang_2c4140fa46.svg",
      },
      {
        name: "C#",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/c_sharp_67fe40e2ec.svg",
      },
      {
        name: "Rust",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/rust_725a2f94a5.svg",
      },
      {
        name: "NestJS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nestjs_ac5444a463.svg",
      },
      {
        name: ".Net Core",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dot_net_core_77801a1659.svg",
      },
    ],
  },
  {
    title: "Frontend",
    tech: [
      {
        name: "HTML5",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html_d0113dcb5b.svg",
      },
      {
        name: "CSS3",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/css_83f429ef10.svg",
      },
      {
        name: "JavaScript",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_faabf30861.svg",
      },
      {
        name: "Sass",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sass_21bc01d4b4.svg",
      },
      {
        name: "ReactJS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_js_80b51d0915.svg",
      },
      {
        name: "VueJS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_d50deb1abc.svg",
      },
      {
        name: "Angular",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/angular_211459e536.svg",
      },
      {
        name: "MeteorJS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/meteor_338f4cddff.svg",
      },
      {
        name: "NextJS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nextjs_f522e422b5.svg",
      },
      {
        name: "Nuxt JS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nuxt_js_7dd794dcd8.svg",
      },
      {
        name: "WebGL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/webgl_4f5f1a3db1.svg",
      },
    ],
  },
  {
    title: "Low/No-Code",
    tech: [
      {
        name: "Shopify",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/shopify_ae04787205.svg",
      },
      {
        name: "WordPress",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/wordpress_21debbb9f0.svg",
      },
      {
        name: "Strapi",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/strapi_c0b3490527.svg",
      },
      {
        name: "Bubble.io",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bubble_io_7d02f6646c.svg",
      },
      {
        name: "Builder.io",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/builder_io_1e34420a3a.svg",
      },
      {
        name: "Zoho Creator",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/zoho_creator_174bcdcec7.svg",
      },
      {
        name: "Zapier",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/zapier_8c058f5724.svg",
      },
      {
        name: "Webflow",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/webflow_31446c7ed1.svg",
      },
      {
        name: "Wix",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/wix_c6ec6b6e8f.svg",
      },
    ],
  },
  {
    title: "Databases",
    tech: [
      {
        name: "Firebase",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/firebase_ef74cf7778.svg",
      },
      {
        name: "MongoDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongodb_712f78e8bc.svg",
      },
      {
        name: "PostgreSQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgres_sql_dcdf437583.svg",
      },
      {
        name: "CouchDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/couch_db_bfe8d0242f.svg",
      },
      {
        name: "Indexed DB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/indexed_db_9d0319ec27.svg",
      },
      {
        name: "SQLite",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sqlite_943d8ea2d2.svg",
      },
      {
        name: "MS SQL Server",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/microsoft_sql_4560777c31.svg",
      },
      {
        name: "DynamoDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dynamo_db_0be048ceef.svg",
      },
      {
        name: "Oracle SQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/oracle_cloud_95b8defe4c.svg",
      },
      {
        name: "MySQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/my_sql_1900f2e96d.svg",
      },
      {
        name: "Redis",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/redis_11f959c7f7.svg",
      },
    ],
  },
  {
    title: "DevOps",
    tech: [
      {
        name: "AWS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_808ca345fb.svg",
      },
      {
        name: "Google Cloud",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/google_cloud_5ed65764bc.svg",
      },
      {
        name: "Azure",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_d89ab06709.svg",
      },
      {
        name: "IBM Cloud",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ibm_cloud_4dc1accfec.svg",
      },
      {
        name: "Oracle Cloud",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/oracle_cloud_95b8defe4c.svg",
      },
      {
        name: "Digital Ocean",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/digital_ocean_4d18428194.svg",
      },
      {
        name: "Puppet",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/puppet_8a9847fcc0.svg",
      },
      {
        name: "Kubernetes",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kubernetes_07c8c8fb6c.svg",
      },
      {
        name: "Docker",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/docker_f16065baec.svg",
      },
      {
        name: "Jenkins",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jenkins_1005ee5533.svg",
      },
      {
        name: "Chef",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/chef_e9df93221b.svg",
      },
      {
        name: "Terraform",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/terraform_5d7f556f97.svg",
      },
    ],
  },
  {
    title: "Mobile",
    tech: [
      {
        name: "Java",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_1daa91a6fb.svg",
      },
      {
        name: "Kotlin",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kotlin_b996d03627.svg",
      },
      {
        name: "Swift",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/swift_e560d7ce3c.svg",
      },
      {
        name: "Objective-C",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/objective_c_28eb9328bc.svg",
      },
      {
        name: "React Native",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_native_1b1b3bed1f.svg",
      },
      {
        name: "Ionic",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ionic_f5150924a1.svg",
      },
      {
        name: "PWA",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pwa_d59a05b04b.svg",
      },
      {
        name: "Flutter",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/flutter_26023bbc4f.svg",
      },
    ],
  },
  {
    title: "AI & ML",
    tech: [
      {
        name: "TensorFlow",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/tensorflow_260eb1d8bc.svg",
      },
      {
        name: "Keras",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/keras_51a9374c9d.svg",
      },
      {
        name: "Pytorch",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pytorch_cac097fda1.svg",
      },
      {
        name: "Hugging Face",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/hugging_face_57d9a28684.svg",
      },
      {
        name: "NLTK",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nltk_b78701d1e9.svg",
      },
      {
        name: "OpenAI",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/open_ai_7b048e1ccc.svg",
      },
      {
        name: "Spacy",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/spacy_c408c2c63b.svg",
      },
      {
        name: "Plotly",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/plotly_89b939597c.svg",
      },
      {
        name: "Matplotlib",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/matplotlib_9a2965443f.svg",
      },
      {
        name: "Pandas",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pandas_134c06b6f2.svg",
      },
      {
        name: "OpenCV",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/opencv_43ed45ffef.svg",
      },
      {
        name: "Numpy",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/numpy_ec8056e8f7.svg",
      },
      {
        name: "SkLearn",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/scikit_learn_1a5385295d.svg",
      },
    ],
  },
]

const TechStack = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="applications">
      <div className={styles.TechStacksWebHireAndroid}>
        <Container>
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div className={styles.heading}>{strapiData?.subTitle}</div>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContentAngular}>
              <div>
                <Nav variant="pills" className={`applications`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockAngularWeb">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                    width={60}
                                    height={60}
                                    style={{ width: "auto", height: "auto" }}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default TechStack
