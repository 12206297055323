import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Benefits.module.scss"
import Col from "react-bootstrap/Col"

const Benefits = ({ strapiData }) => {
  return (
    <div className={styles.BenefitsCSD}>
      <Container>
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.subTitle}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div
          className={styles.subTitle2}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle2,
          }}
        />
        <Row className={styles.rowCSD}>
          {strapiData?.cards?.map((v, i) => (
            <Col key={i} lg={4} md={6} sm={12} className={styles.colCards}>
              <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_2_61de0b9694.svg"
                alt="blue arrow"
                loading="lazy"
                width={48}
                height={48}
              />
              <h3>{v?.title}</h3>
              <p>{v?.subTitle}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Benefits
