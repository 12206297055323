import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Industries.module.scss"
import { Link } from "gatsby"

const Industries = ({ strapiData }) => {
  return (
    <div className={styles.IndustriesCSD}>
      <Container>
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.subTitle}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={styles.rowCards}>
          {strapiData?.cards?.map((v, i) => (
            <Col key={i} lg={3} md={6} className={styles.colCards}>
              <Link
                to={v?.buttons[0]?.url}
                className="position-relative d-inline-block"
              >
                <img
                  src={v?.image1[0]?.localFile?.publicURL}
                  alt={v?.title}
                  loading="lazy"
                  width={290}
                  height={215}
                  style={{width: "auto", height: "auto"}}
                />
                <h3>{v?.title}</h3>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Industries
