import { Link } from "gatsby"
import React, { useRef } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import * as styles from "./CaseStudies.module.scss"

const data = [
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/appwork_case_study_ddc8dda492.webp",
    title: "AppWork",
    head: "Offers automakers a platform to enhance their response to safety recalls.",
    button: "/portfolio/appwork/",
    button2: "Web Application",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/carma_project_case_study_879e17294d.webp",
    title: "Carma Project",
    head: "Offers automakers a platform to enhance their response to safety recalls.",
    // button: "/portfolio/column/",
    button2: "Web Application",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/viewpro_gis_case_study_91aff17e37.webp",
    title: "ViewPro GIS",
    head: "City Planners focused on addressing urban challenges with automated 3D map-based tools",
    // button: "/portfolio/globalreader/",
    button2: "Web Application",
  },
]

const CaseStudies = ({ strapiData }) => {
  const sliderRef = useRef(null)

  const settings = {
    infinite: true,
    autoplay: false,
    slidesToShow: 2,
    speed: 500,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1270,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <p className={styles.ser}>{strapiData?.title}</p>
          <div className={styles.headSearch}>
            <h2 className={styles.heading}>{strapiData?.subTitle}</h2>
            <div className={styles.talkBtn}>
              <Link to={"/portfolio/"} className={styles.btn}>
                See All Case Studies
                <img
                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                  decoding="async"
                  loading="lazy"
                  alt="arrow"
                  width={24}
                  height={24}
                />
              </Link>
            </div>
          </div>
          <Row className="align-items-center">
            <Col lg={12} md={12} sm={12} xs={12}>
              {/* <div className={styles.overlay} /> */}
            </Col>
            <Slider
              {...settings}
              className={styles.SliderWidth}
              ref={sliderRef}
            >
              {data?.map((e, i) => (
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  key={i}
                  className={styles.portfolios}
                  onMouseEnter={() => sliderRef.current.slickPause()}
                  onMouseLeave={() => sliderRef.current.slickPlay()}
                >
                  <div>
                    <img
                      src={e?.images}
                      alt="client logos"
                      decoding="async"
                      loading="lazy"
                      className={styles.clientImgs}
                    />
                    <div className={styles.overly}>
                      <div className={styles.talkBtn2}>
                        <Link to={"/contact-us/"} className={styles.btn2}>
                          {e?.button2}
                        </Link>
                      </div>
                      <div className={styles.content}>
                        <p>{e?.title}</p>
                        <h3>{e?.head}</h3>
                      </div>

                      <div className={styles.logos}>
                        <hr className={styles.line} />
                        <div className={styles.logoBtn}>
                          <img
                            src={e?.logos}
                            alt="client logos"
                            decoding="async"
                            loading="lazy"
                          />
                          <Link to={e?.button} className={styles.btn2}>
                            <img
                              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                              decoding="async"
                              loading="lazy"
                              alt="arrow"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Slider>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default CaseStudies
