import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Offering.module.scss"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Offering = ({ strapiData }) => {
  const [cardVal, setCardVal] = useState(strapiData?.cards[0])
  const changeImg = val => {
    console.log(val)
    setCardVal(val)
  }

  return (
    <div className={styles.OfferingCSD}>
      <Container className={styles.Container}>
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div className={styles.rowCards}>
          <div className={styles.col1}>
            <div>
              <div
                className={styles.subTitle}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <div
                className={styles.subTitle2}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle2,
                }}
              />
              {strapiData?.cards?.map((v, i) => (
                <div
                  className={styles.cards}
                  key={i}
                  onClick={() => changeImg(v)}
                >
                  <h3>{v?.title}</h3>
                  <p
                    className={
                      cardVal?.subTitle === v?.subTitle ? "d-block" : "d-none"
                    }
                  >
                    {v?.subTitle}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.col2}>
            <img
              src={cardVal?.image1 && cardVal?.image1[0]?.localFile?.publicURL}
              alt={cardVal?.title || "image 1x"}
              loading="lazy"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Offering
