// extracted by mini-css-extract-plugin
export var SliderWidth = "CaseStudies-module--SliderWidth--aa30f";
export var Trust = "CaseStudies-module--Trust--6a859";
export var bottomLine = "CaseStudies-module--bottomLine--4ea36";
export var btn = "CaseStudies-module--btn--de639";
export var btn2 = "CaseStudies-module--btn2--454d4";
export var clientImgs = "CaseStudies-module--clientImgs--a78da";
export var content = "CaseStudies-module--content--6418f";
export var devOpsRefineImages = "CaseStudies-module--devOpsRefineImages--27bec";
export var headSearch = "CaseStudies-module--headSearch--850f1";
export var heading = "CaseStudies-module--heading--3e338";
export var logoBtn = "CaseStudies-module--logoBtn--1aecc";
export var logos = "CaseStudies-module--logos--925fc";
export var overly = "CaseStudies-module--overly--62b67";
export var portfolios = "CaseStudies-module--portfolios--4294f";
export var ser = "CaseStudies-module--ser--35a59";
export var talkBtn = "CaseStudies-module--talkBtn--a3bfe";
export var talkBtn2 = "CaseStudies-module--talkBtn2--9b044";
export var topLine = "CaseStudies-module--topLine--2b88e";