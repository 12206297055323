import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./WhyChooseUs.module.scss"

const WhyChooseUs = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.WhyChooseUsCSD}>
      <Container>
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.subTitle}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row>
          <Col
            xl={{ span: 6, order: 1 }}
            lg={{ span: 12, order: 2 }}
            md={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
            className={styles.cards}
          >
            {strapiData?.cards &&
              strapiData?.cards?.map((item, index) => (
                <div className={styles.talent} key={index}>
                  <img
                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_2_61de0b9694.svg"
                    alt={item?.title}
                    loading="lazy"
                    width={36}
                    height={36}
                  />
                  <h3>{item?.title}</h3>
                </div>
              ))}
          </Col>
          <Col
            xl={{ span: 6, order: 2 }}
            lg={{ span: 12, order: 1 }}
            md={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
            className={styles.col2}
          >
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.secImages[0]?.alternativeText}
                decoding="async"
                loading="lazy"
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhyChooseUs
