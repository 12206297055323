import React, { useEffect, useRef, useState } from "react"
import Container from "react-bootstrap/esm/Container"
import * as styles from "./Process.module.scss"

const Process = () => {
  const videoRef = useRef(null)
  const [hasPlayed, setHasPlayed] = useState(false)

  useEffect(() => {
    if (!videoRef.current) return

    const videoElement = videoRef.current

    const handleIntersection = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !hasPlayed) {
          videoElement.play()
          setHasPlayed(true)
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    })

    observer.observe(videoElement)

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement)
      }
    }
  }, [hasPlayed])

  return (
    <div className={styles.Process}>
      <Container>
        <video
          ref={videoRef}
          muted
          style={{ width: "100%", height: "auto" }}
          //   loop
          //   poster="https://invozone-backend.s3.us-east-1.amazonaws.com/home_Page_Banner_1d77028993.webp"
        >
          <source
            src="https://invozone-backend.s3.us-east-1.amazonaws.com/Custom_Software_Development3_6d0f52681e.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      </Container>
    </div>
  )
}

export default Process
