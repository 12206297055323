import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./BannerSec.module.scss"

const BannerSec = ({ strapiData }) => {
  return (
    <div className={styles.BannerSec}>
      <Container>
        <div className={styles.banner}>
          <h2>{strapiData?.title}</h2>
          <Link to={strapiData?.buttons[0]?.url} className={styles.btn}>
            {strapiData?.buttons[0]?.title}
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
              decoding="async"
              loading="lazy"
              alt="explore icon"
              width={24}
              height={24}
            />
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default BannerSec
