import React, { useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Services.module.scss"
import "./Services.scss"
import { Link } from "gatsby"

const ServicesCSD = ({ strapiData }) => {
  const [screenSize, setScreenSize] = useState(0)
  const [isAll, setIsAll] = useState(false)
  const [isLargeScreen, setIsLargeScreen] = useState(false)

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth)
    handleResize()

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    const isLargeScreen1 = screenSize >= 1026 // Bootstrap lg breakpoint is 992px
    // console.log("screenSize", screenSize, window.)
    setIsLargeScreen(isLargeScreen1)
  }, [screenSize])

  return (
    <div className={styles.ServicesCSD}>
      <Container>
        <div>
          <p className={styles.title}>{strapiData?.title}</p>
          <h2 className={styles.subTitle}>{strapiData?.subTitle}</h2>
          <p className={styles.subTitle2}>{strapiData?.subTitle2}</p>
        </div>
        <Row className={styles.rowApp}>
          {strapiData?.cards?.map((v, i) => {
            return (
              <Col
                key={i}
                sm={12}
                lg={4}
                className={`d-flex ${
                  i >= 6 && !isAll && isLargeScreen ? "partialHide" : ""
                }`}
              >
                <div className={styles.servicesCards}>
                  <h3>{v?.title}</h3>
                  <Link to={v?.buttons[0]?.url}>
                    <img
                      src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_7217bbaec7.svg"
                      alt="link arrow"
                      loading="lazy"
                      width={32}
                      height={32}
                    />
                  </Link>
                  <p>{v?.subTitle}</p>
                </div>
              </Col>
            )
          })}
          {isLargeScreen &&
            (!isAll ? (
              <div className={styles.overlay}>
                <div className={styles.outerlay}>
                  <button onClick={() => setIsAll(true)}>
                    Load More Services{" "}
                    <img
                      src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_3_f18a1bb7f9.svg"
                      alt="loading"
                      width={32}
                      height={32}
                    />
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-100 mt-2 d-flex">
                <div className={styles.outerlay}>
                  <button onClick={() => setIsAll(false)}>See Less</button>
                </div>
              </div>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default ServicesCSD
