// extracted by mini-css-extract-plugin
export var ClientsDataPortfoliioRevamp = "Testimonials-module--ClientsDataPortfoliioRevamp--1a0ec";
export var SliderWidth = "Testimonials-module--SliderWidth--352dc";
export var Trust = "Testimonials-module--Trust--05dd4";
export var bannerHeadingPortfolioRevamp = "Testimonials-module--bannerHeadingPortfolioRevamp--ead54";
export var buton = "Testimonials-module--buton--a8385";
export var devOpsRefineImages = "Testimonials-module--devOpsRefineImages--0674a";
export var disabled = "Testimonials-module--disabled--75179";
export var heading = "Testimonials-module--heading--76fe4";
export var icon = "Testimonials-module--icon--284a3";
export var iconContainer = "Testimonials-module--iconContainer--ded47";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--cc758";
export var modalBodytestiRevamp = "Testimonials-module--modalBodytestiRevamp--4dcc6";
export var modalContentTestiRevamp = "Testimonials-module--modalContentTestiRevamp--223e2";
export var name = "Testimonials-module--name--ec898";
export var overlay = "Testimonials-module--overlay--5a0a0";
export var overlay2 = "Testimonials-module--overlay2--60b5a";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--c98a9";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--66165";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--c2473";
export var portfoliosLogos = "Testimonials-module--portfoliosLogos--55f9c";
export var role = "Testimonials-module--role--9acab";
export var roleBtn = "Testimonials-module--roleBtn--0b7e7";
export var ser = "Testimonials-module--ser--72974";
export var sheildImgTop = "Testimonials-module--sheildImgTop--80161";
export var shieldTopBannerImg = "Testimonials-module--shieldTopBannerImg--99855";
export var text = "Testimonials-module--text--a12e5";