// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "TechStackMobile-module--AngularIndusTechHireWeb--98c80";
export var cardBg = "TechStackMobile-module--cardBg--be31f";
export var cards = "TechStackMobile-module--cards--48f6d";
export var cardsContent = "TechStackMobile-module--cardsContent--c09f3";
export var description = "TechStackMobile-module--description--7ad4c";
export var heading = "TechStackMobile-module--heading--28faa";
export var iconssCard = "TechStackMobile-module--iconssCard--f1960";
export var nav = "TechStackMobile-module--nav--18f48";
export var navItem = "TechStackMobile-module--nav-item--baba9";
export var navbarBlock = "TechStackMobile-module--navbarBlock--20e62";
export var tabData = "TechStackMobile-module--tabData--fbf90";
export var tech = "TechStackMobile-module--tech--57854";
export var techIcon = "TechStackMobile-module--techIcon--2be11";
export var techImg = "TechStackMobile-module--techImg--b39e8";
export var technologyIcon = "TechStackMobile-module--technologyIcon--9b953";
export var title = "TechStackMobile-module--title--db4d1";